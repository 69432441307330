import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid/index.js';

import { centeredContentClassName, classNames, pageContentClassName } from '@/utils/class-names';
import { PagePath, allPages } from '@/utils/pages';
import { truthy } from '@/utils/typescript';
import { Link } from './link';

type Page = {
  name: string;
  href: string;
  isCurrent: boolean;
};

export default function Breadcrumbs({
  current,
  omitPages,
}: {
  current: { path: PagePath; name?: string };
  omitPages?: PagePath[];
}) {
  const pagePaths: string[] = current.path
    .split('/')
    .filter(truthy)
    .map((_, index, arr) => `/${arr.slice(0, index + 1).join('/')}/`.replace('//', '/'));

  const pages: Page[] = pagePaths.map((path, index, arr) => {
    const isCurrent = index === arr.length - 1;
    const pageName =
      isCurrent && current.name
        ? current.name
        : allPages[path as keyof typeof allPages] || 'Unknown';
    return {
      name: pageName,
      href: path,
      isCurrent,
    };
  });

  return (
    <>
      <nav className={classNames(centeredContentClassName, 'px-3 pt-4')} aria-label="Breadcrumb">
        <ol role="list" className="flex items-center space-x-2">
          <li>
            <div>
              <Link to="/" className="flex text-gray-400 no-underline hover:text-gray-500">
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0"
                  style={{ marginTop: '-1px' }}
                  aria-hidden="true"
                />
                <span
                  className={
                    pages.length
                      ? 'sr-only'
                      : 'ml-2 text-sm font-medium  text-gray-500 hover:text-gray-700'
                  }
                >
                  Customer Portal Home
                </span>
              </Link>
            </div>
          </li>
          {pages
            .filter((page) => !omitPages || !omitPages.includes(page.href as keyof typeof allPages))
            .map((page) => (
              <li key={page.name}>
                <div className="flex items-center">
                  <ChevronRightIcon
                    className="mr-2 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <Link
                    // @ts-ignore
                    href={page.href}
                    className="text-sm font-medium text-gray-500 no-underline hover:text-gray-700"
                    aria-current={page.isCurrent ? 'page' : undefined}
                  >
                    {page.name}
                  </Link>
                </div>
              </li>
            ))}
        </ol>
      </nav>
      {/* Show confidentiality notice to users in VWGoA portal (required by VW's security assessment) */}
      {pagePaths[0] === '/vwgoa/' && (
        <div role="banner" className="mt-3 bg-yellow-200">
          <div className={classNames(pageContentClassName, 'py-1 text-center text-xs')}>
            Volkswagen Group of America: INTERNAL Data
          </div>
        </div>
      )}
    </>
  );
}
